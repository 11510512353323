<template>
    <div>
        <h1>New Scrap Check</h1>
        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                        <label>Check Date</label>
                        <DatePicker v-model="checkDate" :input-attributes="{class: 'form-control'}"></DatePicker>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                        <label>Plant</label>
                        <select v-model="cdiLocationID" class="form-control">
                            <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                        <label>Weight (lbs)</label>
                        <input type="text" class="form-control" v-model="weight">
                    </div>
                </div>
            </div>
        </div>

        <button @click="add()" class="cdi_button btn btn-primary btn_min_width btn-lg">Submit</button>
    </div>
</template>

<script>
    import { store } from '@/store/Tool.store.js';
    import DateHelpers from "@/store/DateHelpers";
    import cdiLocationCache from '@/cache/cdiLocation.cache';
    import DatePicker from '../utils/DatePicker';

    export default {
        name: "ScrapTracker",
        components: {DatePicker},
        data() {
            return {
                cache: {
                    cdiLocationCache
                },
                checkDate: null,
                cdiLocationID: null,
                weight: null
            }
        },
        created() {
            this.setDefaultValues()
        },
        methods: {
            add: function () {
                let options = {
                    'params[check_date]': this.checkDate,
                    'params[cdi_location_id]': this.cdiLocationID,
                    'params[weight]': this.weight
                };

                store.api('/scrap_tracker/add_check', options);
                this.setDefaultValues();
            },
            setDefaultValues: function () {
                this.weight = null;
                this.cdiLocationID = 1;
                this.checkDate = DateHelpers.getToday();
            }
        }
    }
</script>

<style scoped>

</style>